import React from "react";
import Seo from "../components/seo";
import { RakuuruLayout } from "../components/index";
import { StaticImage } from "gatsby-plugin-image"; //ローカルから画像を持ってくる
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as styles from "../styles/SuccessRakuuru.module.scss";

const SuccessRakuuru = () => {
  const breakpoints = useBreakpoint();

  return (
    <RakuuruLayout className={`${styles.SuccessRakuuru}`} pageName={`rakuurusuccess`} title={`深川養鶏「ぶちうま産直市場」ご利用申請エントリー完了`}>
      <Seo title={`深川養鶏「ぶちうま産直市場」ご利用申請エントリー完了`} />
      <div className={`LowerWrapper`}>
        <div className={`LowerContent`}>
          <div className={`LowerContent__inner`}>
            <h1 className={`${styles.SuccessRakuuru__title}`}>
              深川養鶏
              <br className="smBr" />
              「ぶちうま産直市場」
              <br />
              ご利用申請フォーム
            </h1>
            <div className={`LowerLead`}>
              <p>
                ぶちうま産直市場のご利用申請は下記フォームをご利用ください。
                <br />
                本会員への新規申込に際しましては、決済方法としてご用意している
                <br />
                「クロネコ掛け払い」への同時申込みも兼ねております。
                <br />
                お申込み後、ヤマトグループのヤマトクレジットファイナンス株式会社との間で
                <br />
                お客様情報を共有し所定の審査を行いますことをご了承願います。
                <br />
                個人情報保護方針については<a href="/privacy/">こちら</a>をご覧ください。
              </p>
            </div>
            <div className={styles.SuccessRakuuru__result}>送信が完了いたしました。</div>
            <div className={`${styles.SuccessRakuuru__icon}`}>{breakpoints.sm ? <StaticImage layout="fullWidth" src="../images/contact/success-sp.png" alt="success" placeholder="none" /> : <StaticImage layout="fullWidth" src="../images/contact/success.png" alt="success" placeholder="none" />}</div>
            <div className={styles.SuccessRakuuru__endmsg}>
              <p>
                この度は、お問い合わせフォームよりご連絡をいただき、ありがとうございます。
                <br />
                ご記入の内容でお問い合わせを受け付けました。
                <br />
                内容を確認し、折り返し担当の者よりご連絡いたします。
                <br />
                今後とも、深川養鶏農業協同組合をよろしくお願いいたします。
              </p>
            </div>
          </div>
        </div>
      </div>
    </RakuuruLayout>
  );
};
export default SuccessRakuuru;
